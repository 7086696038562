import { FileOpener } from "@capacitor-community/file-opener"
import { Directory, Filesystem } from "@capacitor/filesystem"
import { isPlatform } from "@ionic/react"
import { toast } from "react-toastify"
import React from "react"
import { saveAs } from "file-saver"
import { downloadDoc, downloadSepa } from "../api/documents"
import { BillingSummary } from "../types/common"

export const toFixedWithoutRounding = (rent: string): string => {
  if (!(rent.toString().split(".")[1].length === 2)) {
    return rent?.toString().concat("0")
  }
  return rent
}

export const handleKeyUp = (e: any): void => {
  if (e.code === "Space") {
    e.target.click()
  }
}

export const assurances: string[] = [
  "AXA",
  "MAAF",
  "ALLIANZ",
  "MACIF",
  "GMF",
  "MATMUT",
  "CREDIT MUTUEL",
  "MAIF",
  "MMA",
  "CIC",
  "DIRECT ASSURANCE",
  "GROUPAMA",
  "CAISSE D'EPARGNE",
  "GENERALI",
  "LA BANQUE POSTALE",
  "CREDIT AGRICOLE",
  "AUTRE",
]

const writeFileAndOpenWithDefault = async (
  path: string,
  data: string | Blob,
  directory: Directory = Directory.Documents
) => {
  await Filesystem.writeFile({
    path,
    data,
    directory,
    recursive: true,
  }).catch((err) => {
    console.error("error:", err.message)
    if (err.message === "FILE_NOTCREATED") {
      toast.error("Une erreur est survenue. Le fichier n'a pas pu être téléchargé.")
    }
    Filesystem.requestPermissions()
  })
  const { uri } = await Filesystem.getUri({
    path,
    directory: Directory.Documents,
  })
  if (uri) {
    await FileOpener.open({ filePath: uri })
  }
}

const checkDirectory = async () => {
  let ok = true;
  await Filesystem.readdir({
    path: "Vilogia",
    directory: Directory.Documents,
  }).catch(async (err) => {
    console.error("checkDirectory_error:", err)
    ok = false
  })
  if (!ok) {
    await Filesystem.mkdir({
      path: "Vilogia",
      directory: Directory.Documents,
    }).catch((e) => e)
  }
}

export const openFile = async (path: string, data: string | Blob) => {
  try {
    await Filesystem.requestPermissions()
    await checkDirectory()
    const reader = new FileReader()
    reader.readAsDataURL(data as Blob)
    reader.onloadend = async () => {
      const b64Result = reader.result!.toString();
      await writeFileAndOpenWithDefault(`Vilogia/${path}`, b64Result)
      // await Filesystem.readdir({
      //   path: "Vilogia",
      //   directory: Directory.Documents,
      // }).then(async (filesData) => {
      //   const { files } = filesData
      //   if (files.length > 0) {
      //     files.forEach((file) => {
      //       const filename = file.name.split(".")
      //       if (file.name === path && !files.some((el) => el.name === `${filename[0]}(1).pdf`)) {
      //         writeFileAndOpenWithDefault(`Vilogia/${filename[0]}(1).pdf`, b64Result)
      //       } else if (file.name.includes("(") && filename[0].at(-1) === ")") {
      //         const originalFilename = filename[0].split("(")
      //         const incrementIndex = parseInt(originalFilename[1].charAt(0), 10) + 1
      //         const newFilename = `${originalFilename[0]}(${incrementIndex}).pdf`
      //         const newPath = `Vilogia/${newFilename}`
      //         const originalPath = path.split(".")[0]
      //         if (originalFilename[0] === originalPath && !files.some((el) => el.name === newFilename)) {
      //           writeFileAndOpenWithDefault(newPath, b64Result)
      //         }
      //       } else if (!files.some((el) => el.name === path)) {
      //         writeFileAndOpenWithDefault(`Vilogia/${path}`, b64Result)
      //       }
      //     })
      //   } else {
      //     await writeFileAndOpenWithDefault(`Vilogia/${path}`, b64Result)
      //   }
      // })
    }
  } catch (error) {
    console.error("error-openFile:", error)
  }
}

export const dl = async (
  filename: string,
  dlName: string,
  setClickState: React.Dispatch<React.SetStateAction<"initial" | "loading" | "success" | "error">>,
  categoryDocument: string | null
) => {
  setClickState("loading")
  await downloadDoc(filename, categoryDocument)
    .then(async (response) => {
      if (isPlatform("capacitor")) {
        const data = await response.data
        await openFile(`${dlName}`, data)
        setClickState("initial")
      } else {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", `${dlName}`)
        document.body.appendChild(link)
        link.click()
        return true
      }
      return false
    })
    .then(() => {
      toast.success("Le document a bien été téléchargé.")
      setClickState("initial");
    })
    .catch((err) => {
      console.error(err)
      toast.error("Il y a eu une erreur lors du téléchargement. Veuillez réessayer.")
      setTimeout(() => {
        setClickState("initial")
      }, 2000)
    })
}

export const getSepaFile = async (
  billingSummary: BillingSummary,
  setClickState: React.Dispatch<React.SetStateAction<"initial" | "loading" | "success" | "error">>
) => {
  const url = `${process.env.REACT_APP_BACKEND}${billingSummary?.sepa}`
  if (isPlatform("capacitor")) {
    setClickState("loading");
    await downloadSepa()
      .then(async (res) => {
        const data = await res.data
        const timestamp = new Date().getTime().toString();
        await openFile(`vilogia-sepa-${timestamp}.pdf`, data)
        setClickState("initial")
      })
      .catch((err) => {
        toast.error("Il y a eu une erreur. Veuillez réessayer.")
        setClickState("error")
        console.error(err)
      })
  } else {
    saveAs(billingSummary?.sepa ? url : "/", "vilogia-sepa.pdf")
  }
}
