import { IonIcon, IonToast, isPlatform } from "@ionic/react"
import React, { useState, useEffect } from "react"

import "./DocumentsList.scss"
import { useHistory } from "react-router"

import Skeleton from "react-loading-skeleton"
import { useSelector } from "react-redux"
import { type RootState } from "stores/reducers"
import { useConfig } from "hooks/config"
import download from "../theme/images/download.svg"
import check from "../theme/images/check.svg"
import error from "../theme/images/close-outline.svg"
import eyeOutline from "../theme/images/eye-outline.svg"
import iconArrow from "../theme/images/icon_arrow.svg"
import { type Document } from "../types/common"

import { downloadDoc } from "../api/documents"

import "react-loading-skeleton/dist/skeleton.css"
import Loader from "./Loader"
import { dl, openFile } from "../utils/functions"
import moment from "moment"

interface DownloadButtonProps {
  filename: string
  dlName: string
  linkTitle: string
  categoryDocument: string
}

function PreviewButton({ filename, dlName, linkTitle, categoryDocument }: DownloadButtonProps) {
  const { data: config } = useConfig()
  const router = useHistory()
  const dark = useSelector((state: RootState) => state.theme.dark)

  const goToPDF = (e: any) => {
    e.preventDefault()
    if (!isPlatform("capacitor")) {
      router.push("/pdf", { filename, dlName, categoryDocument })
    } else {
      downloadDoc(filename, categoryDocument)
        .then(async (response) => {
          const data = await response.data
          const timestamp = new Date().getTime().toString();
          openFile(`${dlName}-${timestamp}.pdf`, data)
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }

  return (
    <a
      href="/pdf"
      className="download-btn focusable"
      onClick={goToPDF}
      aria-label={`Apercevoir le document : ${linkTitle}`}
    >
      {/* Use style on div INSIDE 'a', (safari compatibility) */}
      <div className="download-btn__button">
        <span>Aperçu</span>
        <IonIcon
          aria-hidden="true"
          icon={eyeOutline}
          title="Voir ce document"
          className="download-btn__button__icon"
          style={dark || !config ? undefined : { color: config.primaryColor }}
        />
      </div>
    </a>
  )
}

function DownloadButton({ filename, dlName, linkTitle, categoryDocument }: DownloadButtonProps) {
  const { data: config } = useConfig()
  const dark = useSelector((state: RootState) => state.theme.dark)
  const [clickState, setClickState] = useState<"initial" | "loading" | "success" | "error">("initial")

  useEffect(() => {
    setClickState("initial")

    return () => {
      setClickState("initial")
    }
  }, [])

  let style
  if (config) {
    style = dark || !config ? undefined : { color: config.primaryColor }
  }

  return (
    <>
      <button
        type="button"
        className="download-btn focusable download-btn__button"
        onClick={() => {
          dl(filename, dlName, setClickState, categoryDocument)
        }}
        aria-label={`Télécharger ${linkTitle}`}
      >
        <span>Télécharger</span>
        {clickState === "loading" ? (
          <Loader />
        ) : clickState === "success" ? (
          <IonIcon
            aria-hidden="true"
            icon={check}
            title="Document téléchargé"
            className="download-btn__button__icon"
            style={style}
          />
        ) : clickState === "error" ? (
          <IonIcon
            aria-hidden="true"
            icon={error}
            title="Erreur lors du téléchargargement. Veuillez réessayer."
            className="download-btn__button__icon"
            style={style}
          />
        ) : (
          clickState === "initial" && (
            <IonIcon
              aria-hidden="true"
              icon={download}
              title="Télécharger ce document"
              className="download-btn__button__icon"
              style={style}
            />
          )
        )}
      </button>
      {clickState === "success" ? (
        <IonToast
          isOpen
          translucent
          animated
          duration={4000}
          message="Document téléchargé"
          position="bottom"
          buttons={[
            {
              side: "end",
              icon: "close",
              text: "Fermer",
            },
          ]}
        />
      ) : clickState === "error" ? (
        <IonToast
          isOpen
          translucent
          animated
          duration={4000}
          message="Il y a eu une erreur lors du téléchargement, veuillez réessayer."
          position="bottom"
          buttons={[
            {
              side: "end",
              icon: "close",
              text: "Fermer",
            },
          ]}
        />
      ) : null}
    </>
  )
}

interface DocumentsListProps {
  documents: Document[]
  sorted?: boolean
}

const defaultProps = {
  sorted: false,
}

function DocumentsList({ documents, sorted }: DocumentsListProps) {
  const [dateDesc, setDateDesc] = useState<boolean | undefined>(true)
  const [categoryDesc, setCategoryDesc] = useState<boolean | undefined>(undefined)

  let computedDocuments = documents

  if (dateDesc) {
    computedDocuments = computedDocuments.sort((a, b) => (parseInt(b.documentDate, 10) - parseInt(a.documentDate, 10)))
  } else if (dateDesc === false) {
    computedDocuments = computedDocuments.sort((a, b) => (parseInt(a.documentDate, 10) - parseInt(b.documentDate, 10)))
  } else {
    computedDocuments = documents
  }
  if (categoryDesc) {
    computedDocuments = computedDocuments.sort((a, b) => (b.category < a.category ? 1 : -1))
  } else if (categoryDesc === false) {
    computedDocuments = computedDocuments.sort((a, b) => (a.category < b.category ? 1 : -1))
  }

  const [handleSortDate, sethandleSortDate] = useState(true)
  const [handleSortCategory, sethandleSortCategory] = useState(false)

  const [clicked, setclicked] = useState(false)

  const handleCLickDate = () => {
    setCategoryDesc(undefined)
    sethandleSortDate(true)
    setclicked(!clicked)
    sethandleSortCategory(false)
    if (dateDesc === undefined) {
      setDateDesc(true)
      setclicked(false)
    } else {
      setDateDesc(!dateDesc)
    }
  }

  const handleCLickCategory = () => {
    setDateDesc(undefined)
    sethandleSortCategory(true)
    setclicked(!clicked)
    sethandleSortDate(false)

    if (categoryDesc === undefined) {
      setCategoryDesc(true)
      setclicked(false)
    } else {
      setCategoryDesc(!categoryDesc)
    }
  }

  const displayDate = (doc: Document): string => {
    const mom = moment(doc.documentDate, "YYYYMMDD").format("MM/YYYY")
    return mom
  }

  return (
    <div className="card documents__card">
      <div className="documents__table-wrapper">
        <table className="documents__table">
          <caption className="sr-only">
            Liste de vos documents administratifs, identifiés par deux critères : la date et l&apos;opération,
            c&apos;est à dire le type de document.
          </caption>
          <thead>
            {computedDocuments.length > 0 ? (
              <tr>
                {sorted ? (
                  <>
                    <th scope="col">
                      <button
                        type="button"
                        className="filter__button"
                        onClick={() => {
                          handleCLickDate()
                        }}
                        aria-label="Trier par date"
                      >
                        Date
                        {handleSortDate && (
                          <IonIcon
                            aria-hidden="true"
                            className={`icon_date ${clicked ? "clicked" : ""}`}
                            icon={iconArrow}
                          />
                        )}
                      </button>
                    </th>
                    <th scope="col">
                      <button
                        type="button"
                        className="filter__button"
                        onClick={() => {
                          handleCLickCategory()
                        }}
                        aria-label="Trier par document"
                      >
                        Documents
                        {handleSortCategory && (
                          <IonIcon
                            aria-hidden="true"
                            className={`icon_date ${clicked ? "clicked" : ""}`}
                            icon={iconArrow}
                          />
                        )}
                      </button>
                    </th>
                  </>
                ) : (
                  <>
                    <th scope="col">Dates</th>
                    <th scope="col">Documents</th>
                  </>
                )}
              </tr>
            ) : (
              <tr>
                <th style={{ paddingBottom: 0 }}>Aucun document trouvé</th>
              </tr>
            )}
          </thead>
          <tbody>
            {computedDocuments ? (
              computedDocuments.map((doc) => (
                <tr key={doc.id}>
                  <th scope="row" className="date__document">
                    {displayDate(doc)}
                  </th>
                  <td>
                    <div>
                      <span>{doc.nameDisplay || <Skeleton />}</span>
                      <div className="documents_button-container">
                        <PreviewButton
                          filename={doc.id}
                          dlName={doc.name}
                          categoryDocument={doc.category}
                          linkTitle={`${doc.category} du ${doc.month}/${doc.year}`}
                        />
                        <DownloadButton
                          filename={doc.id}
                          dlName={doc.name}
                          categoryDocument={doc.category}
                          linkTitle={`${doc.category} du ${doc.month}/${doc.year}`}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td>
                  <Skeleton count={10} height={20} className="skeleton_loader--table" />
                </td>
                <td>
                  <Skeleton count={10} height={20} className="skeleton_loader--table" />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

DocumentsList.defaultProps = defaultProps

export default DocumentsList
